import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import AsyncLoad from "@/pages/components/AsyncLoad";
import Auth from "../pages/auth";
// 基本模块
const Login = AsyncLoad(() => import("@/pages/login"));
const Course = AsyncLoad(() => import("@/pages/course"));
const Video = AsyncLoad(() => import("@/pages/videoInfo"));
const Game = AsyncLoad(() => import("@/pages/game"));
const ClassContent = AsyncLoad(() => import("@/pages/classContent"));
const Answer = AsyncLoad(() => import("@/pages/answer"));
const WriteResult = AsyncLoad(() => import("@/pages/writeResult"));
const SeeResult = AsyncLoad(() => import("@/pages/seeResult"));
const CultivationAndEducation = AsyncLoad(() => import("@/pages/yangjiao"));
//个人中心模块路由
const Personal = AsyncLoad(() => import("@/pages/personal"));
const PersonalInfo = AsyncLoad(() => import("@/pages/personal/info"));
const ChangeInfo = AsyncLoad(() => import("@/pages/personal/change"));
const Baby = AsyncLoad(() => import("@/pages/personal/baby"));
const MonthReport = AsyncLoad(() => import("@/pages/personal/monthReport"));
const SaleCase = AsyncLoad(() => import("@/pages/personal/saleCase"));
const SaleDetail = AsyncLoad(() => import("@/pages/personal/saleDetail"));
const Router = () => (
  <HashRouter>
    <Switch>
      <Route path="/" component={Login} exact></Route>
      <Route path="/auth" component={Auth} exact></Route>
      <Route path="/course" component={Course} exact></Route>
      <Route path="/video/:id" component={Video} exact></Route>
      {/* 游戏 */}
      <Route path="/game" component={Game} exact></Route>
      <Route path="/classContent" component={ClassContent} exact></Route>
      <Route path="/answer" component={Answer} exact></Route>
      <Route path="/writeResult" component={WriteResult} exact></Route>
      <Route path="/seeResult" component={SeeResult} exact></Route>
      {/* 养教 */}
      <Route path="/yangjiao" component={CultivationAndEducation} exact></Route>
      {/* 个人中心 */}
      <Route path="/center" component={Personal} exact></Route>
      <Route path="/center/info" component={PersonalInfo} exact></Route>
      <Route path="/center/change" component={ChangeInfo} exact></Route>
      <Route path="/center/baby" component={Baby} exact></Route>
      <Route path="/center/saleCase" component={SaleCase} exact></Route>
      <Route path="/center/saleDetail" component={SaleDetail} exact></Route>
      <Route path="/center/monthReport" component={MonthReport} exact></Route>
    </Switch>
  </HashRouter>
);

export default Router;