import { Toast } from "antd-mobile";
import { getAuthUserInfo } from "../request/login/request";

const Auth = ({location, history}) => {
    const { search } = location;

    if(search && search.startsWith('?openid=')) {
        // 取微信用户信息
        const openId = search.substring(8);
        getAuthUserInfo(openId)
            .then(r => {
                if (r.code) {
                    throw new Error(r.message);
                }
                // 保存微信用户信息到本地 wxUser
                localStorage.setItem('wxUser', JSON.stringify(r.data));
            })
            .catch(e => Toast.fail(e.message))
            .finally(() => history.replace('/'));
    } else {
        history.replace('/');
    }

    return <span>授权处理中</span>
}

export default Auth;
