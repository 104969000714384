import request from "@/utils/request";

//获取验证码
export const sendSms = (params) =>
  request({
    method: "get",
    url: "/mobile/sendSms",
    params,
    isTableData: false,
    mockDuration: 0,
  });
//登录
export const loginByProfile = (params) =>
  request({
    method: "post",
    url: "/mobile/loginByProfile",
    params,
    isTableData: false,
    mockDuration: 0,
  });
//获取用户信息
export const currentUser = (params) =>
  request({
    method: "get",
    url: "/currentUser",
    params,
    isTableData: false,
    mockDuration: 0,
  });

export const getAuthorizeUri = () => request({ method: 'get', url: '/mobile/wechat-mp/authorize_uri' });

export const getAuthUserInfo = openid => request({ method: 'get', url: '/mobile/wechat-mp/get-auth-userinfo', params: { openid } })
