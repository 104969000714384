import React from 'react';
import ReactDOM from 'react-dom';
// import reportWebVitals from './reportWebVitals';
import Router from "@/router/index";
import "@/static/css/global.css";
import 'antd-mobile/dist/antd-mobile.css';
import 'lib-flexible'
// 全局样式
import './index.scss'


// import VConsole from "vconsole";
// const vConsole = new VConsole();

if(window.location.hash && window.location.hash.startsWith('#/center?dealerId=')) {
  if(!localStorage.getItem('currentUser')) {
    window.location.assign('/#/?' + window.location.hash.split('?')[1]);
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>,
  document.getElementById('root')
);

window.enterUrl = window.location.href;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
